@import 'styles/index.scss';

.payment-method {
  .pm-body {
    .ach-reference-container {
      .static-billing-line-item {
        // padding-left: 0.4rem;
      }
    }

    .ach-reference-number-form-container {
      .ach-reference-number-input {
        margin-left: -0.4rem;
      }

      .submit-button {
        align-self: center;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
