@import 'styles/index.scss';

.payment-method {
  .pm-body {
    .check-reference-container {
      .static-billing-line-item {
        // padding-left: 0.4rem;
      }
    }

    .check-reference-number-form-container {
      .check-reference-number-input {
        margin-left: -0.4rem;
      }

      .submit-button {
        align-self: center;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
