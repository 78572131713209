@import 'styles/index.scss';

.transactions-index-page {
  width: 65rem;
  margin: auto;

  .transactions-index-page-content {
    padding: 1.5rem 2rem;
    padding-bottom: 3rem;
    position: relative;
    //background-color: rgba(0, 0, 0, 0.05);

    .transactions-title {
      font-size: 1.4rem;
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }

    .create-transaction {
      position: absolute;
      top: 1.5rem;
      right: 2rem;
    }

    .index-filters {
      padding-top: 4rem;
      padding-bottom: 1.5rem;

      .form-input {
        margin-bottom: 1rem;
        width: 10rem;

        &:not(:last-child) {
          margin-right: 0.5rem;
        }

        .default.text {
          opacity: 0.55;
        }
      }
      .search-input {
        width: 61rem;

        input {
          // border-bottom: 1px solid #ccc;
        }
      }
    }

    .transactions-table-container {
      margin-top: 2rem;
      overflow: scroll;

      .transactions-table {
        display: table;
        margin-bottom: 1rem;
        width: 61rem;
        border-collapse: collapse;

        .header-row {
          font-weight: bold;
          font-size: 0.8rem;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
        .row {
          display: table-row;
          background-color: white;

          &:nth-child(2n) .cell {
              background-color: #fcfcfc;
          }
          &:nth-child(2n + 1):not(.header-row) .cell {
              background-color: #f5f5f5;
          }
          &:not(.header-row) {
            font-size: 0.95rem;

            &:hover {
              .title-cell {
                text-decoration: underline;
              }
            }

            .title-cell {
              max-width: 9rem;
            }

            .cell {
              &:not(:nth-child(2)) {
                &:not(:nth-child(6)) {
                  white-space: nowrap;
                }
              }
            }
          }

          //&:not(:last-child) {
            border-bottom: $border;
            //border-top: none;
          //}

          .cell {
            padding: 0.7rem 1rem;
            display: table-cell;

            &:last-child {
              text-align: right;
              padding-right: 0.4rem;
            }
            &:first-child {
              padding-left: 0.4rem;
            }
          }
        }
      }
    }
    .page-controls {
      width: 61rem;
      display: inline;
      display: flex;

      .page-status {
        padding: 0.5rem 1rem;
        flex-grow: 1;
        text-align: center;
        //width: 5rem;
        font-size: 0.9rem;
        text-transform: uppercase;
        letter-spacing: 1.1px;
      }
      .next-page {
        float: right;
      }
    }
  }
}
