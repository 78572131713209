//general
$spacing: 1rem;
//$primaryColor: #6e8ab5;
//$primaryColor: #222;
$primaryColor: #f0f0f0;
//$secondaryColor: #a5c9c7;
$secondaryColor: #eee;
//$tertiaryColor: #bfdebd;
$tertiaryColor: #eee;
$border: 1px solid $secondaryColor;
//$invalidColor: #d62225;
$invalidColor: #DB4857;
$saveColor: #48AADB;


//brands
$hbBorderColor: #ddd;
