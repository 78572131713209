@import 'styles/index.scss';

.payment-method {
  &:not(:last-child) {
    margin-bottom: $spacing * 2;
  }
  &:not(.is-active) .pm-body {
    display: none;
  }
  &.is-active {
    .is-active-dot {
      background-color: #333;
    }
  }

  .pm-header {
    display: flex;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    .is-active-dot {
      width: 15px;
      height: 15px;
      border-width: 2px;
      border-radius: 50%;
      border-style: solid;
      border-color: #333;
    }

    .pm-header-text {
      margin-left: $spacing;
      position: relative;
      top: -1px;
      font-size: 0.85rem;
      text-transform: uppercase;
      letter-spacing: 1.1px;
    }
  }
  .pm-body {
    padding-top: $spacing * 4;
    padding-bottom: 2 * $spacing;
    // padding-left: $spacing * 2;
    //border-bottom: 1px solid #222;

    .form {
      width: calc(100% + 1rem);

      div {
        text-align: left;
      }

      .partial-payment-container {
        margin-bottom: 3rem;

        .partial-thank-you-note {
          margin: 0 0.4rem;
          padding-bottom: 3rem;
          font-size: 1rem;
          line-height: 2rem;
          // text-transform: uppercase;
          //border-bottom: $border;
          // letter-spacing: 1.3px;
        }

        .partial-payment-note {
          padding-left: 0.4rem;
          font-size: 0.5rem;
          text-transform: uppercase;
          letter-spacing: 0.8px;
        }
      }
    }
    .form-input {
      margin-bottom: $spacing;
      margin-right: $spacing;
    }
    .submit-button {
      margin-top: 1.5 * $spacing;
    }

    .static-billing-container {
      font-size: 0.75rem;
      text-transform: uppercase;
      letter-spacing: 1px;

      .static-billing-line-item {
        margin: auto;
        width: 30rem;
        margin-bottom: 1rem;
        line-height: 1.4rem;

        .static-billing-label, .static-billing-content-container {
          display: inline-block;
          vertical-align: top;
        }

        .static-billing-label {
          padding-right: 3rem;
          width: 55%;
        }

        .static-billing-content-container {
          padding-left: 1rem;
          border-left: $border;

          .static-billing-content {
            opacity: 0.7;
          }
        }
      }

      .form {
        width: 30rem;
        margin: auto;
        margin-top: 2rem;
      }
    }

    @media screen and (max-width:600px) {
      .static-billing-container {
        .static-billing-line-item {
          margin: auto;
          width: 100%;

          .static-billing-label {
            padding-right: 0rem;
            width: 45%;
          }

          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
