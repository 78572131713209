@import 'styles/index.scss';

.show-transaction-page {
  max-width: 51rem;
  margin: auto;
  margin-bottom: 20rem;
  background-color: rgba(0, 0, 0, 0.05);
  position: relative;
  padding-bottom: 2rem;
  //background-color: rgba(0, 0, 0, 0.02);
  // border: 1px solid #eee;
  // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);

  .payment-methods {
    margin: 0 $spacing * 2;
    padding: 2 * $spacing 0;
  }
  .transaction {
    &.is-loading {
      display: none;
    }

    .transaction-info {
      padding-top: 1.5rem;
      padding-bottom: $spacing * 2 + 1rem;
      margin: 0 $spacing * 2;

      .edit-transaction-link {
        font-size: 0.75rem;
        color: #666;
        font-style: italic;
        // text-transform: uppercase;
        // letter-spacing: 1px;

        &:hover {
          cursor: pointer;
          opacity: 0.7
        }
      }

      .transaction-title {
        font-size: 1.4rem;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        margin-bottom: $spacing;
      }
      .transaction-amount-status {
        margin-top: $spacing + $spacing * 2;
        font-size: 1.1rem;
        text-transform: uppercase;
        // letter-spacing: 1.3px;
        opacity: 0.6;
        // font-style: italic;
      }
      .transaction-description {
        margin-top: $spacing + $spacing * 2;
        font-size: 0.9rem;
        line-height: 1.4;

        white-space: pre-line;
      }

      .file-link {
        margin-top: calc(#{$spacing} * 2 + 1rem);

        &:hover {
          opacity: 0.7
        }
      }

      .form-input {
        margin-top: calc(#{$spacing} * 2 + 1rem);
      }
    }
    .payment-methods {
      // margin: 0 $spacing * 2;
      // padding: 2 * $spacing 0;
      padding: $spacing 0rem;
    }

    .thank-you-note, .initialized-thank-you-note {
      padding: calc(2 * #{$spacing} + 1rem + 2px) 0rem;
      margin: 0 $spacing * 2;
      border-bottom: $border;
      font-size: 1rem;
      //text-transform: uppercase;
      //letter-spacing: 1.3px;

      .initialized-thank-you-note-text {
        opacity: 0.7
      }

      .initialized-additional-info-container {
        font-size: 0.8rem;
        line-height: 1.5rem;

        .pm-header-text {
          font-size: 0.8rem;
        }
      }

      .thank-you-note {
        opacity: 0.7;
      }

      div {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }

    .thank-you-note, .initialized-thank-you-note {
      padding-top: 0;
    }
  }
  .transaction-not-prepared-message {
    padding: $spacing * 2;
    line-height: 1.5rem;
  }

  .support-contact-info {
    // margin: 0 $spacing * 2;
    // padding: 2 * $spacing 0;
    margin: 0 $spacing * 2;
    padding-top: 2 * $spacing;
    font-size: 0.6rem;
    text-transform: uppercase;
    letter-spacing: 0.9px;
    line-height: 1.5rem;
    border-top: $border;
  }
}
