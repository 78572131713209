@import 'styles/index.scss';

.payment-method-group {
  &:not(:last-child) {
    margin-bottom: $spacing * 3;
  }
  &.is-active {
    .pm-group-header-text {
      margin-bottom: $spacing * 2;
    }
  }

  .pm-group-header {
    display: flex;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    .pm-group-header-text {
      margin-left: calc(#{$spacing} - 1px);
      position: relative;
      top: -1px;
      font-size: 0.9rem;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1.1px;
    }
  }
}
