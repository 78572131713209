@import 'styles/index.scss';

.app {
  .edit-transaction-page {
    //width: calc(50rem + 2px);
    //width: calc(35rem + 2px);
    max-width: 51rem;
    height: 100%;
    margin: auto;
    position: relative;
    // border: 1px solid $primaryColor;
    // border-radius: 10px;
  }

  .edit-transaction-page-content {
    padding: 1.5rem 2rem;
    //background-color: rgba(0, 0, 0, 0.05);

    .edit-transaction-title-container {
      //border-bottom: $border;

      .edit-transaction-title {
        padding-left: 0.4rem;
        margin-bottom: 1rem;
        font-size: 1.4rem;
        text-transform: uppercase;
        letter-spacing: 1.5px;
      }
    }

    .back-link {
      font-size: 0.75rem;
      color: #666;
      padding-left: 0.4rem;
      font-style: italic;
      // text-transform: uppercase;
      // letter-spacing: 1px;

      &:hover {
        cursor: pointer;
        opacity: 0.7
      }
    }

    .last-notification-text {
      font-size: 0.75rem;
      color: #666;
      font-style: italic;
      position: absolute;
      right: calc(2rem + 85.02px + 1rem + 1rem);
    }

    .edit-transaction-form-container {
      padding-top: 2rem;

      .form {
        .wrapping-form-fields {
          width: calc(100% + 1rem);
        }

        #file-upload-button {
          -webkit-appearance: none;
          appearance: none;
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;
        }

        .file-upload-container {
          text-align: right;
          margin-bottom: 3rem;
          // position: absolute;
          // top: 1.5rem;
          // right: calc(1.5rem + 96.02px + 1rem);

          .file-link {
            padding: 0.5rem 2rem;
            font-size: 0.75rem;
            font-style: italic;
            color: #666;
            // text-transform: uppercase;
            // letter-spacing: 1px;

            &:hover {
              opacity: 0.7
            }
          }
        }

        .form-input {
          &.is-disabled {
            opacity: 0.5;
          }

          &.multiline {
            width: 47rem;
          }
          &.has-unsaved-changes:not(.is-invalid) {
            .selection {
              border-bottom: 1px solid $saveColor;
            }
            input {
              &:not(.search) {
                border-bottom: 1px solid $saveColor;
              }
            }
          }
          &:not(textarea) {
            margin-bottom: $spacing * 2;

            &.description-input {
              //margin-top: 2rem;
            }
            &.internal-notes-input {
              margin-bottom: 2rem;
            }
            // &:not(:nth-child(2n)) {
            //   margin-right: $spacing;
            // }
            margin-right: $spacing;
          }

          textarea {
            padding: .5rem;
            background-color: rgba(0, 0, 0, 0.02);
            //resize: none;
            outline: none;

            height: 10rem;
            max-width: 100%;
            min-width: 100%;
            border: $border;

            &.has-unsaved-changes:not(.is-invalid) {
              border: 1px solid $saveColor;
            }
            &.is-invalid {
              border: 1px solid $invalidColor;
            }
          }
        }

        .submit-button {
          position: absolute;
          bottom: 1.5rem;
          right: 2rem;
        }

        .preview-button {
          position: absolute;
          top: 1.5rem;
          right: 2rem;
        }

        .email-client-button {
          position: absolute;
          top: 1.5rem;
          right: calc(2rem + 85.02px + 1rem);
        }

        .payment-received-button {
          position: absolute;
          top: 1.5rem;
          right: calc(2rem + 85.02px + 1rem);
        }

        .delete-button {
          background-color: #E83F56;
          color: rgba(255, 255, 255, 0.9);
        }
      }

      &.is-invalid {
        .form {
          .submit-button {
            background-color: $invalidColor;
            //color: rgba(255, 255, 255, 0.8);
          }
        }
      }
      &.has-unsaved-changes:not(.is-invalid) {
        .form {
          .submit-button {
            background-color: $saveColor;
            //color: rgba(255, 255, 255, 0.8);
          }
        }
      }
    }
  }
}
